import { getWithAuth, getWithOutAuth, postWithAuth } from '@services/http.service';
import { UrlParamsReplace } from '@services/url.service';

import type { zoomAuthEventType } from '@lib/zoom-helpers/types';

const ZOOM_USER_PLATFORM_API = (id: string) => UrlParamsReplace('users/:id/zoom-platforms', { id });
const ZOOM_EVENTS = () => UrlParamsReplace('platforms/zoom/events');
const ZOOM_EVENT_SESSIONS = (eventId: string) => UrlParamsReplace('platforms/zoom/events/:eventId/sessions', { eventId });
const ZOOM_EVENT_JOIN_TOKEN = (eventId: string, sessionId: string) => UrlParamsReplace('platforms/zoom/events/:eventId/sessions/:sessionId/join-token', { eventId, sessionId });
const ZOOM_GET_CHALLENGE_CODE = () => UrlParamsReplace('platforms/zoom/get-challenge-code');
const ZOOM_AUTH_CALLBACK = (eventPayload: zoomAuthEventType) => UrlParamsReplace('platforms/zoom/auth', {}, eventPayload);
const ZOOM_RESOURCE_API = (resourceType: string, resourceId: string, queryParams: object) => UrlParamsReplace(
  'platforms/zoom/:resourceType/:resourceId',
  { resourceType, resourceId },
  queryParams
);
const ZOOM_REGISTER_RESOURCE_API = (resourceType: string, resourceId: string) => UrlParamsReplace(
  'platforms/zoom/:resourceType/:resourceId/register',
  { resourceType, resourceId }
);

export const getZoomUserPlatform = (id: string) => getWithAuth(ZOOM_USER_PLATFORM_API(id));

export const getZoomEvents = () => getWithAuth(ZOOM_EVENTS());

export const getZoomEventSessions = (eventId: string) => getWithAuth(ZOOM_EVENT_SESSIONS(eventId));

export const getZoomEventJoinToken = (eventId: string, sessionId: string) => getWithAuth(ZOOM_EVENT_JOIN_TOKEN(eventId, sessionId));

export const getZoomChallengeCode = () => getWithAuth(ZOOM_GET_CHALLENGE_CODE());

export const zoomAuthCallback = (eventPayload: zoomAuthEventType) => getWithOutAuth(ZOOM_AUTH_CALLBACK({ ...eventPayload, state: decodeURIComponent(eventPayload.state) }));

export const getZoomResource = (resourceType: string, resourceId: string, queryParams: object) => getWithAuth(ZOOM_RESOURCE_API(resourceType, resourceId, queryParams));

export const registerForResource = (resourceType: string, resourceId: string, payload: object) => postWithAuth(ZOOM_REGISTER_RESOURCE_API(resourceType, resourceId), payload);
